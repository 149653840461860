import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import WorkIcon from "@mui/icons-material/Work";
import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import ChurchIcon from "@mui/icons-material/Church";
import SynagogueIcon from "@mui/icons-material/Synagogue";
import LiquorIcon from "@mui/icons-material/Liquor";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import MainTitle from "../../../../shared/base/MainTitle";

export default function TimelineComponent() {
  const getVerticalTimelineElement = ({
    title = "",
    subtitle = "",
    date = "",
    icon = <WorkIcon />,
    iconStyle = {},
    contentStyle = {},
    contentArrowStyle = {},
    description = "",
  }) => {
    return (
      <VerticalTimelineElement
        contentStyle={contentStyle}
        contentArrowStyle={contentArrowStyle}
        date={date}
        iconStyle={iconStyle}
        icon={icon}
      >
        <h3 className="vertical-timeline-element-title">{title}</h3>
        <h4 className="vertical-timeline-element-subtitle">{subtitle}</h4>
        <p>{description}</p>
      </VerticalTimelineElement>
    );
  };
  return (
    <FlexColumn fullWidth fullHeight jcCenter aiCenter>
      <MainTitle title="Timeline" />
      <VerticalTimeline>
        {getVerticalTimelineElement({
          title: "Misa",
          subtitle: "Catedral León",
          date: "5:00 P.M.",
          icon: <ChurchIcon />,
          iconStyle: { background: "rgb(33, 150, 243)", color: "#fff" },
          //contentStyle: { background: "rgb(33, 150, 243)", color: "#fff" },
          //contentArrowStyle: { borderRight: "7px solid  rgb(33, 150, 243)" },
          description: "",
        })}
        {getVerticalTimelineElement({
          title: "Boda Cívil",
          subtitle: "Hacienda La Cassia",
          date: "3:00 P.M.",
          icon: <SynagogueIcon />,
          iconStyle: { background: "#D5C8BE", color: "#fff" },
          description: "",
        })}
        {getVerticalTimelineElement({
          title: "Fiesta",
          subtitle: "Hacienda La Cassia",
          date: "7:00 P.M.",
          icon: <LiquorIcon />,
          iconStyle: { background: "rgb(233, 30, 99)", color: "#fff" },
          description: "",
        })}
      </VerticalTimeline>
    </FlexColumn>
  );
}
