import { put, takeLatest } from "redux-saga/effects";

import { DETECT_SCREEN } from "../../redux/global/constants";
import { setScreen, setScreenSize } from "../../redux/global/actions";

import { BREAKPOINTS } from "../../config/breakpoints";

export function* setDeviceScreen() {
  const isDesktop = window.innerWidth >= BREAKPOINTS.MD;
  const isMobileLandscape =
    BREAKPOINTS.SM <= window.innerWidth &&
    window.innerWidth <= BREAKPOINTS.MLW &&
    window.innerHeight <= BREAKPOINTS.MLH;
  const isMobilePortrait = window.innerWidth < BREAKPOINTS.SM;
  yield put(setScreenSize(window.innerHeight, window.innerWidth));
  yield put(
    setScreen(isDesktop, !isDesktop, isMobileLandscape, isMobilePortrait)
  );
}

export default function* rootSaga() {
  yield takeLatest(DETECT_SCREEN, setDeviceScreen);
}
