/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import { FlexRow, FlexColumn } from "../../../../../view/shared/base/FlexBox";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import Text from "../../../../../view/shared/base/Text";
import useTimerCalculation from "../../../../../hooks/useTimerCalculation";
import styles from "./styles.module.scss";
import bgDesktop from "../../../../../assets/images/bg_horizontal.jpg";
import bgMobile from "../../../../../assets/images/bg_vertical.jpg";
import useLayout from "../../../../../hooks/useLayout";
import { useSpring, animated } from "@react-spring/web";

const DeadLineCounter = ({ show }) => {
  if (!show) return null;
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  const deadlineDate = 1740182400000;
  const { isDesktop } = useLayout();

  const { days, hours, minutes, seconds } = useTimerCalculation({
    endDate: deadlineDate,
  });

  const textFontSize = { xs: 10, xl: 16 };

  const getClockBlock = (numbers, text) => {
    return (
      <FlexRow relative key={`${numbers}-${text}`}>
        <FlexColumn
          m={{ xs: 0.5, md: 1 }}
          jcCenter
          aiCenter
          className={styles.square}
          gap={3}
          width={{ xs: 50, sm: 60, md: 100 }}
          height={"auto"}
          py={5}
          px={2}
          key={numbers}
        >
          <Text h4 colorInherit>
            {numbers.toString().padStart(2, "0")}
          </Text>
          <Text
            colorInherit
            bold
            nowrap
            sx={{
              fontSize: textFontSize,
            }}
          >
            {text}
          </Text>
        </FlexColumn>
      </FlexRow>
    );
  };

  const cssDesktop = {
    position: "absolute",
    left: "50%",
    top: 30,
    transform: "translateX(-50%)",
  };
  const cssMobile = {
    position: "absolute",
    top: 30,
    left: "50%",
    transform: "translateX(-50%)",
  };

  return (
    <FlexRow relative fullWidth>
      <BoxImage
        mobile={isDesktop ? bgDesktop : bgMobile}
        desktop={isDesktop ? bgDesktop : bgMobile}
        sx={{ width: "100%" }}
      />
      <animated.div style={props}>
        <FlexColumn
          gap={3}
          sx={isDesktop ? { ...cssDesktop } : { ...cssMobile }}
        >
          <Text xs_h2 md_h1 alignCenter>
            Solo Faltan:
          </Text>
          <FlexRow jcCenter aiCenter>
            {getClockBlock(days, "DÍAS")}

            {getClockBlock(hours, "HORAS")}

            {getClockBlock(minutes, "MINUTOS")}

            {getClockBlock(seconds, "SEGUNDOS")}
          </FlexRow>
        </FlexColumn>
      </animated.div>
    </FlexRow>
  );
};

DeadLineCounter.propTypes = {};

export default DeadLineCounter;
