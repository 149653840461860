/*istanbul ignore file*/
/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from "immer";
import {
  API_FAIL,
  API_INITIALIZED,
  API_PENDING,
  API_SUCCESS,
  requestFail,
  requestPending,
  requestSuccess,
} from "../../core/redux/api/request";
import {
  DEV_MIXIN_USER,
  EVENTS_LOAD_ALL,
  GET_FAVORITE_APP,
  GET_FEATURE_SELLING,
  GET_TOS,
  GET_USER_INFO,
  GET_USER_INFO_SILENT,
  GET_USER_PLAY_CREDIT_BALANCE,
  INIT_SNACKBAR_PROPS,
  LOAD_STORE_CONFIG,
  LOCATION_HISTORY_ACTION,
  LOG_OUT_AUTH,
  LOGGER_ACTION,
  PATCH_USER_METADATA,
  PUT_WB_BALANCE_DATA,
  RELOAD_USER_WITH_CHECK,
  RESET_CONFIG_DATA,
  SET_ALTERNATIVE_NAME,
  SET_APP_MOUNTED,
  SET_AUTHENTICATED,
  SET_CONFIG_DATA,
  SET_CURRENT_GAME_ID,
  SET_FEATURE_NFT,
  SET_IS_AUTH_PAGE,
  SET_LP_DROPDOWN_OPEN,
  SET_SCREEN,
  SET_SCREEN_SIZE,
  SET_SIGN_IN_MODAL_CONFIG,
  SET_SIGN_UP_MODAL_CONFIG,
  SET_SNACKBAR,
  SET_TOS,
  SIGN_IN_AUTH,
  SIGN_IN_COMPLETE,
  SIGN_IN_DIRECT_WITH_SOCIAL,
  SIGN_IN_FAIL,
  SIGN_IN_RESEND_VERIFICATION,
  SIGN_IN_SOCIAL_AUTH,
  SIGN_UP,
  SIGN_UP_RESEND_VERIFICATION,
  UPDATE_USER_AFTER_BUNDLE_PURCHASE,
  UPDATE_USER_EMAIL,
} from "../../redux/global/constants";
import { proceedRequestStatus } from "../../utils/redux";
import { LocationHistoryAction } from "../../config/history";

// The initial state of the App

const initialSnackbarProps = {
  open: false,
  error: false,
  className: null,
  alertVariant: "filled",
  children: null,
  duration: 3000,
  horizontal: "center",
  message: "",
  onClose: undefined,
  vertical: "top",
  dataTestId: null,
};

export const initialState = {
  user: {},
  config: {},
  isAppMounted: false,
  isDesktop: false,
  isPhone: true,
  isMobilePortrait: true,
  isMobileLandscape: false,
  signInModalConfig: undefined,
  signUpModalConfig: undefined,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  authUser: {},
  getStoreConfigStatus: API_INITIALIZED,
  setTosStatus: API_INITIALIZED,
  currentGameId: null,
  favoriteAppId: null,
  apiRequestStatus: API_INITIALIZED,
  setFeatureNftStatus: API_INITIALIZED,
  getFeatureSellingStatus: API_INITIALIZED,
  featureSelling: {},
  userPlayCreditBalance: null,
  getFavoriteAppStatus: API_INITIALIZED,
  getUserStatus: API_INITIALIZED,
  getUserSilentStatus: API_INITIALIZED,
  getUserPlayCreditBalanceStatus: API_INITIALIZED,
  loginStatus: API_INITIALIZED,
  loginDirectWithSocialStatus: API_INITIALIZED,
  signupStatus: API_INITIALIZED,
  loginResendVerificationStatus: API_INITIALIZED,
  isAuthenticated: false,
  lpDropDownOpen: false,
  reloadUserFromWithCheckStatus: API_INITIALIZED,
  updateUserAfterBundlePurchaseStatus: API_INITIALIZED,
  snackbarProps: initialSnackbarProps,
  isInAuthPage: false,
  lastLoggerEventData: undefined,
  locationHistoryStack: [],
  wbBalanceData: {
    chipSrc: undefined,
    balance: undefined,
  },
  //events
  events: [],
  eventsLoadAllStatus: API_INITIALIZED,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    proceedRequestStatus(
      draft,
      action,
      GET_USER_INFO_SILENT,
      (draft, status) => (draft.getUserSilentStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      GET_USER_PLAY_CREDIT_BALANCE,
      (draft, status) => (draft.getUserPlayCreditBalanceStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      SET_TOS,
      (draft, status) => (draft.setTosStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      SIGN_IN_DIRECT_WITH_SOCIAL,
      (draft, status) => (draft.loginDirectWithSocialStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      SET_FEATURE_NFT,
      (draft, status) => (draft.setFeatureNftStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      SIGN_IN_RESEND_VERIFICATION,
      (draft, status) => (draft.loginResendVerificationStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      LOAD_STORE_CONFIG,
      (draft, status) => (draft.getStoreConfigStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      RELOAD_USER_WITH_CHECK,
      (draft, status) => (draft.reloadUserFromWithCheckStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      UPDATE_USER_AFTER_BUNDLE_PURCHASE,
      (draft, status) => (draft.updateUserAfterBundlePurchaseStatus = status)
    );
    proceedRequestStatus(
      draft,
      action,
      EVENTS_LOAD_ALL,
      (draft, status) => (draft.eventsLoadAllStatus = status)
    );
    switch (action.type) {
      case SET_APP_MOUNTED:
        draft.isAppMounted = true;
        break;

      case LOGGER_ACTION:
        draft.lastLoggerEventData = action.payload.data;
        break;

      case PUT_WB_BALANCE_DATA:
        draft.wbBalanceData = action.payload;
        break;

      case SET_CONFIG_DATA:
        draft.config = action.payload;
        break;

      case requestSuccess(GET_TOS):
      case requestSuccess(SET_TOS):
        draft.termsOfService = action.payload;
        break;

      case RESET_CONFIG_DATA:
        draft.config = action.payload;
        draft.getStoreConfigStatus = API_INITIALIZED;
        break;

      case requestSuccess(GET_FEATURE_SELLING):
        draft.getFeatureSellingStatus = API_SUCCESS;
        draft.featureSelling = action.payload;
        break;
      case SET_SCREEN:
        draft.isDesktop = action.isDesktop;
        draft.isPhone = action.isPhone;
        draft.isMobileLandscape = action.isMobileLandscape;
        draft.isMobilePortrait = action.isMobilePortrait;
        break;

      case SET_SCREEN_SIZE:
        draft.windowWidth = action.windowWidth;
        draft.windowHeight = action.windowHeight;
        break;

      case SET_AUTHENTICATED:
        draft.isAuthenticated = action.isAuthenticated;
        break;
      case SET_LP_DROPDOWN_OPEN:
        draft.lpDropDownOpen = action.payload;
        break;
      case requestSuccess(LOG_OUT_AUTH):
        draft.authUser = {};
        draft.user = {};
        draft.games = [];
        draft.isAuthenticated = false;
        draft.favoriteAppId = null;
        draft.getFavoriteAppStatus = API_INITIALIZED;
        break;

      case requestPending(SIGN_IN_AUTH):
        draft.loginStatus = API_PENDING;
        break;

      case requestPending(SIGN_IN_SOCIAL_AUTH):
        draft.loginStatus = API_PENDING;
        break;

      case SIGN_IN_COMPLETE:
        draft.loginStatus = API_SUCCESS;
        draft.isAuthenticated = true;
        break;

      case SIGN_IN_FAIL:
        draft.loginStatus = API_FAIL;
        draft.isAuthenticated = false;
        break;

      // signup
      case requestPending(SIGN_UP):
      case requestPending(SIGN_UP_RESEND_VERIFICATION):
        draft.signupStatus = API_PENDING;
        break;

      case requestFail(SIGN_UP):
      case requestFail(SIGN_UP_RESEND_VERIFICATION):
        draft.signupStatus = API_FAIL;
        break;

      case requestSuccess(SIGN_UP):
      case requestSuccess(SIGN_UP_RESEND_VERIFICATION):
        draft.signupStatus = API_SUCCESS;
        break;

      // getUserInfo
      case requestPending(GET_USER_INFO):
        draft.getUserStatus = API_PENDING;
        break;
      case requestSuccess(GET_USER_INFO_SILENT):

      case requestFail(GET_USER_INFO):
        draft.getUserStatus = API_FAIL;
        break;
      case requestSuccess(GET_USER_PLAY_CREDIT_BALANCE):
        draft.userPlayCreditBalance = action.payload?.balance;
        break;

      case SET_ALTERNATIVE_NAME:
        draft.user.AlternativeName = action.alternativeName;
        break;
      // getFavoriteApp
      case requestPending(GET_FAVORITE_APP):
        draft.getFavoriteAppStatus = API_PENDING;
        break;
      case requestSuccess(GET_FAVORITE_APP):
        draft.getFavoriteAppStatus = API_SUCCESS;
        draft.favoriteAppId = action.payload;
        break;
      case requestFail(GET_FAVORITE_APP):
        draft.getFavoriteAppStatus = API_FAIL;
        break;
      case requestSuccess(PATCH_USER_METADATA):
        draft.user.HideFTUE = true;
        break;

      // setCurrentGameId
      case SET_CURRENT_GAME_ID:
        {
          const { id, preserve } = action.payload;
          if (!state.currentGameId || !preserve) {
            draft.currentGameId = id;
          }
        }
        break;

      case requestSuccess(RELOAD_USER_WITH_CHECK):
        draft.user = action.payload;
        break;

      case UPDATE_USER_EMAIL:
        draft.user.UserEmail = action.payload;
        break;

      case SET_SNACKBAR:
        draft.snackbarProps = { ...draft.snackbarProps, ...action.payload };
        break;

      case INIT_SNACKBAR_PROPS:
        draft.snackbarProps = initialSnackbarProps;
        break;

      case SET_IS_AUTH_PAGE:
        draft.isInAuthPage = action.payload;
        break;

      case LOCATION_HISTORY_ACTION:
        const { action: method, pathname } = action.payload;
        draft.locationHistoryStack ||= [];

        switch (method) {
          case LocationHistoryAction.pop:
            draft.locationHistoryStack.pop();
            break;

          case LocationHistoryAction.push:
            draft.locationHistoryStack.push(pathname);
            break;

          case LocationHistoryAction.replace:
            draft.locationHistoryStack = [
              ...draft.locationHistoryStack.slice(
                0,
                draft.locationHistoryStack.length - 1
              ),
              pathname,
            ];
            break;
        }
        break;

      case SET_SIGN_IN_MODAL_CONFIG:
        draft.signInModalConfig = action.payload;
        break;

      case SET_SIGN_UP_MODAL_CONFIG:
        draft.signUpModalConfig = action.payload;
        break;

      //events
      case requestSuccess(EVENTS_LOAD_ALL):
        draft.events = action.payload;
        break;

      default:
        break;
    }
  });

export default appReducer;
