import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { FlexBoxConfig } from "./config";


export const FlexBox = ({ isRow, children, ...props }) => {
    let boxProps = {
        display: "flex",
        flexDirection: isRow ? "row" : "column"
    };

    for (let key in props) {
        const value = props[key];

        if (value === undefined) continue;

        if (FlexBoxConfig.direction[key]) {
            if (value) boxProps.flexDirection = FlexBoxConfig.direction[key];
        } else if (FlexBoxConfig.justifyContentProps[key]) {
            if (value) boxProps.justifyContent = FlexBoxConfig.justifyContentProps[key];
        } else if (FlexBoxConfig.justifySelfProps[key]) {
            if (value) boxProps.justifySelf = FlexBoxConfig.justifyContentProps[key];
        } else if (FlexBoxConfig.alignItemsProps[key]) {
            if (value) boxProps.alignItems = FlexBoxConfig.alignItemsProps[key];
        } else if (FlexBoxConfig.alignSelfProps[key]) {
            if (value) boxProps.alignSelf = FlexBoxConfig.alignItemsProps[key];
        } else if (FlexBoxConfig.alignContentProps[key]) {
            if (value) boxProps.alignContent = FlexBoxConfig.alignContentProps[key];
        } else if (FlexBoxConfig.gapProps[key]) {
            if (value) boxProps[key] = value;
        } else if (FlexBoxConfig.other[key] !== undefined) {
            boxProps = {
                ...boxProps,
                ...FlexBoxConfig.other[key](value)
            };
        } else {
            boxProps[key] = value;
        }
    }

    return <Box {...boxProps}>{children}</Box>;
};

export const FlexRow = props => {
    return <FlexBox isRow={true} {...props} />;
};

export const FlexColumn = props => {
    return <FlexBox isRow={false} {...props} />;
};

FlexBox.propTypes = FlexColumn.propTypes = FlexRow.propTypes = {
    sx: PropTypes.object,
    hide: PropTypes.bool,
    relative: PropTypes.bool,
    // gap
    gap: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    rowGap: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    columnGap: PropTypes.number,
    // direction
    dirColumnReverse: PropTypes.bool,
    dirRowReverse: PropTypes.bool,
    // justify content
    jcStart: PropTypes.bool,
    jcCenter: PropTypes.bool,
    jcEnd: PropTypes.bool,
    jcSpaceBetween: PropTypes.bool,
    jcSpaceAround: PropTypes.bool,
    jcSpaceEvenly: PropTypes.bool,
    // align items
    aiStart: PropTypes.bool,
    aiCenter: PropTypes.bool,
    aiEnd: PropTypes.bool,
    aiStretch: PropTypes.bool,
    aiBaseline: PropTypes.bool,
    // align self
    asStart: PropTypes.bool,
    asCenter: PropTypes.bool,
    asEnd: PropTypes.bool,
    asStretch: PropTypes.bool,
    asBaseline: PropTypes.bool,
    // align content
    acStart: PropTypes.bool,
    acCenter: PropTypes.bool,
    acEnd: PropTypes.bool,
    acStretch: PropTypes.bool,
    acBaseline: PropTypes.bool,
    // sizes
    grow: PropTypes.number,
    shrink: PropTypes.number,
    basis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // other
    wrap: PropTypes.bool,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool
};
