import styles from "./styles.module.scss";

const TextConfig = {
    breakpoints: ["xs", "sm", "md", "lg", "xl"],
    fontWeights: ["light", "regular", "medium", "bold", "bolder"],
    defaults: {
        variant: "body2",
        color: "textPrimary",
        component: "span"
    },
    variantsProps: {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        h6: "h6",
        body1: "body1",
        body2: "body2",
        caption: "caption",
        inherit: "inherit",
        subtitle1: "subtitle1",
        subtitle2: "subtitle2"
    },
    colorsProps: {
        colorInherit: "inherit",
        colorPrimary: "primary",
        colorSecondary: "secondary",
        colorTextPrimary: "textPrimary",
        colorTextSecondary: "textSecondary"
    },
    decorationsProps: {
        relative: styles.relative,
        ellipsis: styles.ellipsis,
        italic: styles.italic,
        nowrap: styles.nowrap,
        underline: styles.underline,
        alignLeft: styles.alignLeft,
        alignCenter: styles.alignCenter,
        alignRight: styles.alignRight,
        alignJustify: styles.alignJustify,
        uppercase: styles.textTransformUpperCase,
        capitalize: styles.textTransformCapitalize,
        textDecorationNone: styles.textDecorationNone,
        textTransformNone: styles.textTransformNone,
        fontInherit: styles.fontInherit,
        fontSizeInherit: styles.fontSizeInherit,
        fontWeightInherit: styles.fontWeightInherit,
        lineHeightInherit: styles.lineHeightInherit,
        colorTextLink: styles.colorTextLink,
        colorPrimaryLink: styles.colorPrimaryLink,
        colorWhite: styles.colorWhite,
        colorError: styles.colorError,
        colorLightGray: styles.colorLightGray,
        colorRed: styles.colorRed,
        lineThrough: styles.lineThrough
    },
    css: {
        block: styles.block,
        fullWidth: styles.fullWidth,
        pointer: styles.pointer,
        noUserSelect: styles.noUserSelect,
        disabled: styles.disabled,
        hidden: styles.hidden
    },
    style: {
        top: value => ({ top: value }),
        fontWeight: value => ({ fontWeight: value }),
        letterSpacing: value => ({ letterSpacing: value }),
        lineHeight: value => ({ lineHeight: `${value}${value < 4 ? "" : "px"}` }),
        visible: value => (value !== undefined ? { visibility: value ? "visible" : "hidden" } : {})
    },
    other: {
        span: () => ({ component: "span" }),
        div: () => ({ component: "div" })
    }
};

export default TextConfig;
