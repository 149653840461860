/*istanbul ignore file*/
import {
  DETECT_SCREEN,
  SET_SCREEN,
  SET_SCREEN_SIZE,
  SET_APP_MOUNTED,
} from "./constants";

/**
 * Tells it to detect screen size for responsive UI
 */
export function detectScreen() {
  return {
    type: DETECT_SCREEN,
  };
}

/**
 * Determines if the screen is for desktop or phone
 *
 * @param  {boolean} isDesktop isDesktop boolean value
 * @param  {boolean} isPhone isPhone boolean value
 *
 * @return {object} An action object with a type of SET_SCREEN, isDesktop, and isPhone
 */

export function setScreen(
  isDesktop,
  isPhone,
  isMobileLandscape,
  isMobilePortrait
) {
  return {
    type: SET_SCREEN,
    isDesktop,
    isPhone,
    isMobileLandscape,
    isMobilePortrait,
  };
}

export function setScreenSize(windowHeight, windowWidth) {
  return {
    type: SET_SCREEN_SIZE,
    windowHeight,
    windowWidth,
  };
}

export function setAppMounted() {
  return {
    type: SET_APP_MOUNTED,
  };
}
