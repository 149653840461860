import React, { useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { makeSelectIsDesktop } from "../redux/global/selectors";
import useMediaQuery from "@mui/material/useMediaQuery";
import muiTheme from "../config/muiTheme";

const isScreenPortrait = () => window.screen.height >= window.screen.width;

const useLayout = () => {
  const isInitialized = useRef(false);
  const orientationChangeHandlers = useRef([]);
  const windowResizeHandlers = useRef([]);
  const [isPortrait, setIsPortrait] = useState(isScreenPortrait());
  const isDesktop = useSelector(makeSelectIsDesktop);
  //
  const isXs = useMediaQuery(muiTheme.breakpoints.between("0", "479"));
  const isSm = useMediaQuery(muiTheme.breakpoints.between("480", "767"));
  const isMd = useMediaQuery(muiTheme.breakpoints.between("768", "991"));
  const isLg = useMediaQuery(muiTheme.breakpoints.between("992", "1409"));
  const isXl = useMediaQuery(muiTheme.breakpoints.up("1410"));
  //
  const isSmDown = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const isMdDown = useMediaQuery(muiTheme.breakpoints.down("md"));
  const isLgDown = useMediaQuery(muiTheme.breakpoints.down("lg"));
  const isXlDown = useMediaQuery(muiTheme.breakpoints.down("xl"));
  //
  const isXsUp = useMediaQuery(muiTheme.breakpoints.up("xs"));
  const isMdUp = useMediaQuery(muiTheme.breakpoints.up("md"));
  const isLgUp = useMediaQuery(muiTheme.breakpoints.up("lg"));
  const isXlUp = useMediaQuery(muiTheme.breakpoints.up("xl"));

  const onResize = () => {
    windowResizeHandlers.current.forEach((handler) => handler());
    setIsPortrait(isScreenPortrait());
  };

  const debouncedResize = debounce(onResize, 100);

  const init = (init) => {
    if (init) window.addEventListener("resize", debouncedResize);
    else window.removeEventListener("resize", debouncedResize);
  };

  useEffect(() => {
    return () => init(false);
  }, []);

  useEffect(() => {
    orientationChangeHandlers.current.forEach((handler) => {
      handler({ portrait: isPortrait });
    });
  }, [isPortrait]);

  const handleOrientationChange = (handler, { remove } = {}) => {
    if (remove) {
      orientationChangeHandlers.current =
        orientationChangeHandlers.current.filter((x) => x !== handler);
    } else {
      orientationChangeHandlers.current.push(handler);
    }

    if (!isInitialized.current) {
      init(true);
      isInitialized.current = true;
    }
  };

  const handleWindowResize = (handler, { remove } = {}) => {
    if (remove) {
      windowResizeHandlers.current = windowResizeHandlers.current.filter(
        (x) => x !== handler
      );
    } else {
      windowResizeHandlers.current.push(handler);
    }

    if (!isInitialized.current) {
      init(true);
      isInitialized.current = true;
    }
  };

  return {
    isDesktop,
    handleOrientationChange,
    handleWindowResize,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isSmDown,
    isMdDown,
    isLgDown,
    isXlDown,
    isXsUp,
    isMdUp,
    isLgUp,
    isXlUp,
  };
};

export default useLayout;
