import React from "react";
import styles from "./styles.module.scss";

const FloatingButton = ({ onClick }) => (
  <button
    className={styles.floatingButton}
    onClick={onClick}
    sx={{ zIndex: 1 }}
  >
    &#127881;
  </button>
);

export default FloatingButton;
