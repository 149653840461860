import {
  API_FAIL,
  API_PENDING,
  API_PENDING_SILENT,
  API_SUCCESS,
  requestFail,
  requestPending,
  requestPendingSilent,
  requestSuccess,
} from "../core/redux/api/request";

export const proceedRequestStatus = (
  draft,
  action,
  requestActionType,
  setter
) => {
  switch (action.type) {
    case requestPending(requestActionType):
      setter(draft, API_PENDING);
      break;

    case requestPendingSilent(requestActionType):
      setter(draft, API_PENDING_SILENT);
      break;

    case requestSuccess(requestActionType):
      setter(draft, API_SUCCESS);
      break;

    case requestFail(requestActionType):
      setter(draft, API_FAIL);
      break;
  }
};
