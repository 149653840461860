/*istanbul ignore file*/
/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_SOMETHING = "loyaltyweb/global/LOAD_SOMETHING";
export const LOAD_SOMETHING_SUCCESS = "loyaltyweb/global/LOAD_SOMETHING_SUCCESS";
export const LOAD_SOMETHING_ERROR = "loyaltyweb/global/LOAD_SOMETHING_ERROR";

export const DEV_MIXIN_USER = "loyaltyweb/global/DEV_MIXIN_USER";

export const LOGGER_ACTION = "loyaltyweb/global/LOGGER_ACTION";

export const PUT_WB_BALANCE_DATA = "loyaltyweb/global/PUT_WB_BALANCE_DATA";

export const LOCATION_HISTORY_ACTION = "loyaltyweb/global/LOCATION_HISTORY_ACTION";

export const SET_APP_MOUNTED = "loyaltyweb/global/SET_APP_MOUNTED";
export const DETECT_SCREEN = "loyaltyweb/global/DETECT_SCREEN";
export const SET_SCREEN = "loyaltyweb/global/SET_SCREEN";
export const SET_SCREEN_SIZE = "loyaltyweb/global/SET_SCREEN_SIZE";

export const SET_AUTHENTICATED = "loyaltyweb/global/SET_AUTHENTICATED";
export const SET_LP_DROPDOWN_OPEN = "loyaltyweb/global/SET_LP_DROPDOWN_OPEN";
export const SIGN_IN_AUTH = "loyaltyweb/global/SIGN_IN_AUTH";
export const SIGN_IN_DIRECT_WITH_SOCIAL = "loyaltyweb/global/SIGN_IN_DIRECT_WITH_SOCIAL";
export const SIGN_IN_SOCIAL_AUTH = "loyaltyweb/global/SIGN_IN_SOCIAL_AUTH";
export const SIGN_IN_AND_AUTHENTICATE = "loyaltyweb/global/SIGN_IN_AND_AUTHENTICATE";
export const SIGN_IN = "loyaltyweb/global/SIGN_IN";
export const SIGN_IN_COMPLETE = "loyaltyweb/global/SIGN_IN_COMPLETE";
export const SIGN_IN_FAIL = "loyaltyweb/global/SIGN_IN_FAIL";
export const SIGN_IN_RESEND_VERIFICATION = "loyaltyweb/global/SIGN_IN_RESEND_VERIFICATION";

export const LOG_OUT = "loyaltyweb/global/LOG_OUT";
export const LOG_OUT_AUTH = "loyaltyweb/global/LOG_OUT_AUTH";

export const GET_FAVORITE_APP = "loyaltyweb/global/GET_FAVORITE_APP";
export const GET_USER_INFO = "loyaltyweb/global/GET_USER_INFO";
export const GET_USER_INFO_SILENT = "loyaltyweb/global/GET_USER_INFO_SILENT";
export const GET_USER_PLAY_CREDIT_BALANCE = "loyaltyweb/global/GET_USER_PLAY_CREDIT_BALANCE";
export const SET_CURRENT_GAME_ID = "loyaltyweb/global/SET_CURRENT_GAME_ID";

export const SET_FEATURE_NFT = "loyaltyweb/global/SET_FEATURE_NFT";
export const SET_ALTERNATIVE_NAME = "loyaltyweb/global/SET_ALTERNATIVE_NAME";
export const PATCH_USER_METADATA = "loyaltyweb/global/PATCH_USER_METADATA";

export const GET_FEATURE_SELLING = "loyaltyweb/global/GET_FEATURE_SELLING";

export const RELOAD_USER_WITH_CHECK = "loyaltyweb/global/RELOAD_USER_WITH_CHECK";
export const UPDATE_USER_EMAIL = "loyaltyweb/global/UPDATE_USER_EMAIL";

export const SET_SNACKBAR = "loyaltyweb/global/SET_SNACKBAR";
export const INIT_SNACKBAR_PROPS = "loyaltyweb/global/INIT_SNACKBAR_PROPS";

export const UPDATE_USER_AFTER_BUNDLE_PURCHASE = "loyaltyweb/global/UPDATE_USER_AFTER_BUNDLE_PURCHASE";

export const SET_IS_AUTH_PAGE = "loyaltyweb/global/SET_IS_AUTH_PAGE";

export const SIGN_UP = "loyaltyweb/global/SIGN_UP";
export const SIGN_UP_RESEND_VERIFICATION = "loyaltyweb/global/SIGN_UP_RESEND_VERIFICATION";

export const SET_SIGN_IN_MODAL_CONFIG = "loyaltyweb/global/SET_SIGN_IN_MODAL_CONFIG";
export const SET_SIGN_UP_MODAL_CONFIG = "loyaltyweb/global/SET_SIGN_UP_MODAL_CONFIG";

// config
export const SET_CONFIG_DATA = "loyaltyweb/global/SET_CONFIG_DATA";
export const RESET_CONFIG_DATA = "loyaltyweb/global/RESET_CONFIG_DATA";
export const LOAD_STORE_CONFIG = "loyaltyweb/global/LOAD_STORE_CONFIG";

//terms
export const GET_TOS = "loyaltyweb/global/GET_TOS";
export const SET_TOS = "loyaltyweb/global/SET_TOS";

//
export const AB_TEST_PWD_GET_EXPIRE_DATE = "loyaltyweb/global/AB_TEST_PWD_GET_EXPIRE_DATE";

//events
export const EVENTS_LOAD_ALL = "loyaltyweb/global/EVENTS_LOAD_ALL";
