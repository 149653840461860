/*istanbul ignore file*/
import React from "react";
import { useTimer } from "react-timer-hook";

function useTimerCalculation({ endDate, onExpire }) {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(endDate),
    onExpire: () => onExpire?.call(),
  });
  return { days: days, hours: hours, minutes: minutes, seconds: seconds };
}

export default useTimerCalculation;
