import React from "react";
import { FlexRow } from "../FlexBox";
import styles from "./styles.module.scss";
const Divider = ({ sx }) => {
  return (
    <FlexRow fullWidth height={2} className={styles.divider} sx={{ ...sx }} />
  );
};

export default Divider;
