/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import { FlexColumn } from "../../../../../view/shared/base/FlexBox";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import "./styles.module.scss";
import logo from "../../../../../assets/images/logo.png";

const WeddingTitle = () => {
  return (
    <FlexColumn
      relative
      aiCenter
      jcCenter
      p={{
        xs: 15,
        md: 30,
      }}
    >
      <BoxImage
        mobile={logo}
        desktop={logo}
        sx={{
          width: "100%",
          minWidth: 200,
          maxWidth: 500,
          borderRadius: "40px",
        }}
      />
    </FlexColumn>
  );
};

export default WeddingTitle;
