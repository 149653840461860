/*istanbul ignore next*/
export const FlexBoxConfig = {
    direction: {
        dirColumnReverse: "column-reverse",
        dirRowReverse: "row-reverse"
    },
    gapProps: {
        gap: "gap",
        rowGap: "rowGap",
        columnGap: "columnGap"
    },
    justifyContentProps: {
        jcStart: "flex-start",
        jcCenter: "center",
        jcEnd: "flex-end",
        jcSpaceBetween: "space-between",
        jcSpaceAround: "space-around",
        jcSpaceEvenly: "space-evenly"
    },
    justifySelfProps: {
        jsStart: "flex-start",
        jsCenter: "center",
        jsEnd: "flex-end",
        jsSpaceBetween: "space-between",
        jsSpaceAround: "space-around",
        jsSpaceEvenly: "space-evenly"
    },
    alignItemsProps: {
        aiStart: "flex-start",
        aiCenter: "center",
        aiEnd: "flex-end",
        aiStretch: "stretch",
        aiBaseline: "baseline"
    },
    alignSelfProps: {
        asStart: "flex-start",
        asCenter: "center",
        asEnd: "flex-end",
        asStretch: "stretch",
        asBaseline: "baseline"
    },
    alignContentProps: {
        acStart: "flex-start",
        acCenter: "center",
        acEnd: "flex-end",
        acStretch: "stretch",
        acBaseline: "baseline"
    },
    other: {
        visible: value => ({ visibility: value ? "visible" : "hidden" }),
        hide: value => (value ? { display: "none" } : {}),
        grow: value => ({ flexGrow: value }),
        relative: () => ({ position: "relative" }),
        shrink: value => ({ flexShrink: value }),
        basis: value => ({ flexBasis: value }),
        wrap: value => ({ flexWrap: value ? "wrap" : "nowrap" }),
        fullWidth: () => ({ width: "100%" }),
        fullHeight: () => ({ height: "100%" })
    }
};
