/*istanbul ignore file*/
/**
 * The global state selectors
 */

import { createSelector } from "reselect";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import {
  API_FAIL,
  API_PENDING,
  API_SUCCESS,
} from "../../core/redux/api/request";
import { initialState } from "./reducer";

export const selectGlobal = (state) => state.global || initialState;

export const makeSelectIsAppMounted = createSelector(
  selectGlobal,
  (globalState) => globalState.isAppMounted
);

export const makeSelectIsDesktop = createSelector(
  selectGlobal,
  (globalState) => globalState.isDesktop
);

export const makeSelectWindowWidth = createSelector(
  selectGlobal,
  (globalState) => globalState.windowWidth
);

export const makeSelectWindowHeight = createSelector(
  selectGlobal,
  (globalState) => globalState.windowHeight
);

export const makeSelectDesktopLayoutForMobile = createSelector(
  selectGlobal,
  (globalState) =>
    (globalState.windowHeight < 450 && globalState.windowWidth > 650) ||
    globalState.isDesktop
);

export const makeSelectAuthUser = createSelector(
  selectGlobal,
  (globalState) => globalState.user.authUser
);

export const makeSelectIsAuthenticated = createSelector(
  selectGlobal,
  (globalState) => globalState.isAuthenticated
);

export const makeSelectLpDropDownOpen = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "lpDropDownOpen", "")
);

export const makeSelectLoginPending = createSelector(
  selectGlobal,
  (globalState) => globalState.loginStatus === API_PENDING
);

export const makeSelectLoginDirectWithSocialStatus = createSelector(
  selectGlobal,
  (globalState) => globalState.loginDirectWithSocialStatus
);

export const makeSelectLoginResendVerificationStatusPending = createSelector(
  selectGlobal,
  (globalState) => globalState.loginResendVerificationStatus === API_PENDING
);

export const makeSelectSignupLoading = createSelector(
  selectGlobal,
  (globalState) => globalState.signupStatus === API_PENDING
);

export const makeSelectUser = createSelector(
  selectGlobal,
  (globalState) => globalState.user || {}
);

export const makeSelectHost = createSelector(
  selectGlobal,

  (globalState, invitationState) => {
    const user = globalState.user;
    const invitedUser = invitationState.playerInfo;
    if (!isEmpty(user)) {
      return {
        hostId: get(user, "HostId") || null,
        hostName: get(user, "HostName") || null,
        hostEmail: get(user, "HostEmail") || null,
      };
    } else if (!isEmpty(invitedUser)) {
      return {
        hostId: get(invitedUser, "HostId") || null,
        hostName: get(invitedUser, "HostName") || null,
        hostEmail: get(invitedUser, "HostEmail") || null,
      };
    } else {
      return null;
    }
  }
);

export const makeSelectGetUserStatus = createSelector(
  selectGlobal,
  (globalState) => globalState.getUserStatus
);
export const makeSelectGetUserSilentStatus = createSelector(
  selectGlobal,
  (globalState) => globalState.getUserSilentStatus
);
export const makeSelectUserPlayCreditBalance = createSelector(
  selectGlobal,
  (globalState) => globalState.userPlayCreditBalance
);
export const makeSelectGetUserPlayCreditBalanceStatus = createSelector(
  selectGlobal,
  (globalState) => globalState.getUserPlayCreditBalanceStatus
);

export const makeSelectReloadUserWithCheckLoading = createSelector(
  selectGlobal,
  (globalState) => globalState.reloadUserFromWithCheckStatus === API_PENDING
);

export const makeSelectCurrentGameId = createSelector(
  selectGlobal,
  (globalState) => globalState.currentGameId
);

export const makeSelectGetFavoriteAppComplete = createSelector(
  selectGlobal,
  (globalState) =>
    globalState.getFavoriteAppStatus === API_SUCCESS ||
    globalState.getFavoriteAppStatus === API_FAIL
);

export const makeSelectFavoriteAppId = createSelector(
  selectGlobal,
  (globalState) => globalState.favoriteAppId
);

export const makeSelectIsUserBlocked = createSelector(
  selectGlobal,
  (globalState) => globalState.user.Blocked
);
export const makeSelectLoyaltyBalance = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "user.LoyaltyBalance")
);

export const makeSelectUpdateUserAfterBundlePurchasePending = createSelector(
  selectGlobal,
  (globalState) =>
    globalState.updateUserAfterBundlePurchaseStatus === API_PENDING
);

export const makeSelectUseSnackBarProps = createSelector(
  selectGlobal,
  (globalState) => globalState.snackbarProps
);

export const makeSelectPunchCards = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "user.PunchCards") || {}
);

export const makeSelectIsAuthPage = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "isInAuthPage")
);

export const makeLocationHistoryStack = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "locationHistoryStack", [])
);

export const makeLastLoggerEventData = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "lastLoggerEventData")
);

export const makeWbBalanceData = createSelector(selectGlobal, (globalState) =>
  get(globalState, "wbBalanceData", {})
);

export const selectFeatureSelling = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "featureSelling", {})
);

export const makeGetFeatureSellingStatus = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "getFeatureSellingStatus")
);

export const makeSelectSetFeatureNftStatus = createSelector(
  selectGlobal,
  (globalState) => get(globalState, "setFeatureNftStatus", "")
);

export const makeSelectSignInModalConfig = createSelector(
  selectGlobal,
  (globalState) => globalState?.signInModalConfig ?? {}
);

export const makeSelectSignUpModalConfig = createSelector(
  selectGlobal,
  (globalState) => globalState?.signUpModalConfig ?? {}
);

export const makeSelectConfig = createSelector(
  selectGlobal,
  (state) => state.config ?? {}
);

export const makeSelectGetStoreConfigStatus = createSelector(
  selectGlobal,
  (state) => state.getStoreConfigStatus
);

export const makeSelectSetTosStatus = createSelector(
  selectGlobal,
  (state) => state.setTosStatus
);

export const makeSelectTos = createSelector(
  selectGlobal,
  (state) => state.termsOfService
);

export const selectEventsData = createSelector(selectGlobal, (state) => ({
  events: state.events,
  eventsLoadAllStatus: state.eventsLoadAllStatus,
}));
