import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import { FlexColumn } from "../../../view/shared/base/FlexBox";
import WeddingTitle from "./components/WeddingTitle";
import DeadLineCounter from "./components/DeadLineCounter";
import Gallery from "./components/Gallery";
import WhenAndWhere from "./components/WhenAndWhere";
import DeckComponent from "./components/DeckComponent";
import TimelineComponent from "./components/TimelineComponent";
import DressCode from "./components/DressCode";
import { Box } from "@mui/material";
import FloatingButton from "./components/FloatingButton";
import ConfettiExplosion from "react-confetti-explosion";
import GoodParents from "./components/GoodParentsComponent";

let cx = classNames.bind(styles);

export function PageHome() {
  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const handleButtonClick = () => {
    setTriggerConfetti(true);
  };

  return (
    <Box className={styles.root}>
      {triggerConfetti && (
        <FlexColumn
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <ConfettiExplosion
            duration={5000}
            colors={["#fec04f", "#edba7a", "#ffedc0", "#f6a725", "#e19201"]}
            particleCount={500}
            onComplete={() => setTriggerConfetti(false)}
          />
        </FlexColumn>
      )}
      <WeddingTitle />
      <DeadLineCounter show />

      <FlexColumn jcCenter aiCenter className={styles.page}>
        <FloatingButton onClick={handleButtonClick} />
        <GoodParents />
        <DeckComponent />
        <Gallery />
        <WhenAndWhere />
        <DressCode />
        <TimelineComponent />
      </FlexColumn>
    </Box>
  );
}

export default PageHome;
