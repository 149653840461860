import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FlexColumn, FlexRow } from "../../../../shared/base/FlexBox";
import MainTitle from "../../../../shared/base/MainTitle";
import Text from "../../../../shared/base/Text";

const GoodParentsComponent = () => {
  return (
    <FlexColumn py={10} gap={3}>
      <Text italic h3 alignCenter>
        Acompañanos a celebrar la unión de nuestras vidas
      </Text>
      <Text alignCenter h2>
        Nuestros Padres
      </Text>
      <Text h3 alignCenter>
        Luz María Gamiño Atilano
      </Text>
      <Text h4 italic alignCenter>
        Padres de la novia
      </Text>
      <Text h3 alignCenter>
        Baltazar Solano Ramírez
      </Text>
      <Text h3 alignCenter>
        Carmen
      </Text>
      <Text h4 italic alignCenter>
        Padres del novio
      </Text>
      <Text h3 alignCenter>
        Francisco
      </Text>
      <Accordion sx={{ backgroundColor: "#f0e6d6" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Text xs_h2 md_h1 py={5} bold alignCenter>
            Nuestros Padrinos
          </Text>
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
    </FlexColumn>
  );
};

export default GoodParentsComponent;
