import { createTheme } from "@mui/material/styles";
import { COLORS } from "../config/colors";

const pxToRem = (px) => px / 16 + "rem";

const muiTheme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1410,
    },
  },
  palette: {
    primary: {
      main: COLORS.PS_ORANGE,
    },
    pink: {
      main: COLORS.MAIN,
      light: COLORS.MAIN_LIGHT,
    },
    secondary: {
      main: "#696a93",
    },
    orange: {
      main: COLORS.PS_ORANGE,
      dark: COLORS.PS_ORANGE_DARK,
    },
    text: {
      primary: "#2b2b2b",
      secondary: "#696a93",
    },
  },
  typography: {
    fontFamily: `"Bodonif", "Roboto", "Helvetica"`,
    fontWeightRegular: 400,
    h1: {
      fontSize: pxToRem(38),
      lineHeight: 1,
      fontWeight: 400,
    },
    h2: {
      fontSize: pxToRem(36),
      lineHeight: 1,
      fontWeight: 400,
    },
    h3: {
      fontSize: pxToRem(28),
      lineHeight: 1,
      fontWeight: 400,
    },
    h4: {
      fontSize: pxToRem(26),
      lineHeight: 1,
      fontWeight: 400,
    },
    h5: {
      fontSize: pxToRem(24),
      lineHeight: 1,
      fontWeight: 400,
    },
    h6: {
      fontSize: pxToRem(22),
      lineHeight: 1,
      fontWeight: 400,
    },
    caption: {
      fontSize: pxToRem(20),
      lineHeight: 1,
      fontWeight: 400,
    },
    body1: {
      fontSize: pxToRem(18),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    body2: {
      fontSize: pxToRem(16),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: pxToRem(14),
      lineHeight: 1.2,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: pxToRem(12),
      lineHeight: 1.2,
      fontWeight: 400,
    },
  },
});

export default muiTheme;
