import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import useLayout from "../../../../../hooks/useLayout";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";

let cx = classNames.bind(styles);

const BoxImage = ({
  sx,
  hide,
  alt = "",
  widthInherit,
  autoWidth,
  absolute,
  relative,
  autoHeight,
  mobile,
  desktop,
  className,
  ...props
}) => {
  const { isDesktop } = useLayout;
  if (hide) return <></>;

  return (
    <Box
      sx={sx}
      alt={alt}
      className={cx(className, {
        [styles.autoWidth]: autoWidth,
        [styles.widthInherit]: widthInherit,
        [styles.autoHeight]: autoHeight,
        [styles.absolute]: absolute,
        [styles.relative]: relative,
      })}
      src={isDesktop ? desktop || mobile : mobile}
      component="img"
      {...props}
    />
  );
};

BoxImage.propTypes = {
  sx: PropTypes.object,
  alt: PropTypes.string,
  hide: PropTypes.bool,
  absolute: PropTypes.bool,
  widthInherit: PropTypes.bool,
  autoWidth: PropTypes.bool,
  autoHeight: PropTypes.bool,
  mobile: PropTypes.string,
  desktop: PropTypes.string,
  className: PropTypes.string,
};

export default BoxImage;
