/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import PropTypes from "prop-types";
import Divider from "../../../../view/shared/base/Divider";
import Text from "../../../../view/shared/base/Text";

const MainTitle = ({ title }) => {
  return (
    <>
      <Divider />
      <Text xs_h2 md_h1 py={5} bold alignCenter>
        {title}
      </Text>
      <Divider sx={{ mb: 5 }} />
    </>
  );
};

MainTitle.propTypes = {
  title: PropTypes.string,
};

export default MainTitle;
