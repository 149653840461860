/* eslint-disable react-hooks/rules-of-hooks */
/*istanbul ignore file*/
import React from "react";
import {
  FlexRow,
  FlexColumn,
  FlexBox,
} from "../../../../../view/shared/base/FlexBox";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import MainTitle from "../../../../shared/base/MainTitle";
import Text from "../../../../../view/shared/base/Text";
import styles from "./styles.module.scss";
import bg from "../../../../../assets/images/cassia.jpg";
import catedral from "../../../../../assets/images/catedral.jpeg";
import cassia from "../../../../../assets/images/cassia.jpg";

import useLayout from "../../../../../hooks/useLayout";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { hexToRgb } from "@mui/material";

const WhenAndWhere = () => {
  const { isDesktop } = useLayout();

  const Card = ({
    image,
    title,
    subtitle,
    schedule,
    address = "",
    href = "",
  }) => {
    return (
      <FlexColumn
        jcStart
        aiCenter
        fullHeight
        sx={{
          backgroundColor: "#D5C8BE",
          borderRadius: "10px",
          minWidth: 250,
          maxWidth: 450,
          minHeight: 450,
          maxHeight: 650,
        }}
        m={5}
        gap={4}
      >
        <BoxImage
          mobile={image}
          desktop={image}
          sx={{
            width: "100%",
            minWidth: 200,
            maxWidth: 450,
            borderRadius: "10px",
            aspectRatio: "1.5/1",
          }}
        />
        <FlexColumn gap={3} p={5} fullWidth jcCenter aiCenter>
          <Text xs_h2 md_h3 lg_h2 bold alignCenter>
            {title}
          </Text>
          <Text caption alignCenter>
            {subtitle}
          </Text>
          <Text caption alignCenter>
            {schedule}
          </Text>

          <LocationOnIcon />
          <Text subtitle1 alignCenter>
            <Link to={href} target="_blank" rel="noreferrer">
              {address}
            </Link>
          </Text>
        </FlexColumn>
      </FlexColumn>
    );
  };

  return (
    <FlexColumn fullWidth jcCenter aiCenter pt={6}>
      <MainTitle title="¿Cuándo y dónde?" />

      <FlexBox isRow={isDesktop} fullHeight fullWidth jcCenter aiCenter>
        {Card({
          image: catedral,
          title: "Ceremonia Religiosa",
          subtitle: "Catedral León ",
          schedule: "3:00 P.M.",
          address: "Álvaro Obregón 112, Centro, 37000 León de los Aldama, Gto.",
          href: "https://g.co/kgs/zbVyxg2",
        })}
        {Card({
          image: cassia,
          title: "Fiesta",
          subtitle: "Hacienda La Cassia ",
          schedule: "7:00 P.M.",
          address:
            "Blvd. Aeropuerto 2401, Fracciones de los Aguirre, 37680 León, Gto., León, Guanajuato 37680.",
          href: "https://g.co/kgs/wn4fH3E",
        })}
      </FlexBox>
    </FlexColumn>
  );
};

export default WhenAndWhere;
