// Breakpoints duplicating material-ui breakpoints (https://material-ui.com/customization/breakpoints/)
/*istanbul ignore next*/
export const BREAKPOINTS = Object.freeze({
    XS: 0,
    SM: 480,
    MD: 768,
    LG: 992,
    XL: 1410,
    MLW: 940,
    MLH: 500
});
