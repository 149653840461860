import React from "react";
import { FlexColumn, FlexBox } from "../../../../shared/base/FlexBox";
import { Link } from "react-router-dom";
import Text from "../../../../shared/base/Text";
import MainTitle from "../../../../shared/base/MainTitle";
import useLayout from "../../../../../hooks/useLayout";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import hombre from "../../../../../../src/assets/images/hombre.jpg";
import mujer from "../../../../../../src/assets/images/mujer.png";

const DressCode = () => {
  const { isDesktop } = useLayout();
  const DressCodeCard = ({ image, title, href, address = "" }) => {
    return (
      <FlexColumn
        jcStart
        aiCenter
        fullHeight
        sx={{
          backgroundColor: "#D5C8BE",
          borderRadius: "10px",
          minWidth: 250,
          maxWidth: 450,
          minHeight: 450,
          maxHeight: 650,
        }}
        m={5}
        gap={4}
      >
        <BoxImage
          mobile={image}
          desktop={image}
          sx={{
            width: "100%",
            minWidth: 200,
            maxWidth: 450,
            borderRadius: "10px",
            aspectRatio: "1/1",
          }}
        />
        <FlexColumn gap={3} p={5} fullWidth jcCenter aiCenter>
          <Text xs_h2 md_h3 lg_h2 bold alignCenter>
            {title}
          </Text>
          <Text subtitle1 alignCenter wrap>
            <Link to={href} target="_blank" rel="noreferrer">
              {address}
            </Link>
          </Text>
        </FlexColumn>
      </FlexColumn>
    );
  };

  return (
    <FlexColumn gap={5} fullWidth fullHeight aiCenter jcCenter pt={10}>
      <MainTitle title="Código de vestimenta" />
      <Text h3> Formal</Text>

      <FlexBox isRow={isDesktop} jcCenter aiCenter>
        {DressCodeCard({
          image: hombre,
          title: "Smoking",
          href: "https://www.pinterest.com/bowselectie/mens-formal-wear/",
          address: "Ver ejemplos",
        })}
        {DressCodeCard({
          image: mujer,
          title: "Vestido largo",
          href: "https://www.pinterest.com/leahjrivera/amazing-evening-dresses/",
          address: "Ver ejemplos",
        })}
      </FlexBox>
    </FlexColumn>
  );
};
export default DressCode;
